.add-folder-widget {
  width: 100%;

  .afw-button-container {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: flex-start;
    background-color: #ddd;
  }
  .folderText {
    width: 99%;
  }
}
