.tree-container.pointer {
  cursor: pointer;
}

.tree-container.grabbing {
  cursor: grabbing;
}

.tree-container ul {
  list-style: none;
  padding: 0;
}

.mc-flex-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  align-items: center;
  column-gap: 5px;
}

.mc-flex-row .mc-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clear-both {
  clear: both;
}

.mc-flex-row .mc-col-1 {
  flex: 0 0 30px;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.mc-flex-row .fb-col-1 {
  flex: 0 0 30px;
  appearance: none;
}

.mc-flex-row .mc-col-2,
.fb-col-2 {
  flex: 2 1 0;
}

.mc-flex-row .mc-col .item-1 {
  margin: 5px 0px;
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e0e0e0;
  border-bottom: 2px solid #666e6b;
}
