.color-picker {
  position: relative;
  display: inline-block;
}

.picker-icon {
  flex-direction: column;
  font-size: 11px !important;
  gap: 2px;
}

.line {
  height: 2.5px;
  width: 65%;
  background: #212529;
}

.color-picker__item {
  width: 20px;
  height: 20px;
  border: 1px solid #f1f1f1;
  cursor: pointer;

  &:hover {
    border-color: #ccc;
    transform: scale(1.1);
  }

  &.selected {
    border-color: #ccc;
    transform: scale(1.1);
  }
}

.colors-container {
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 10px;
  display: none;
  position: absolute;
  left: 5px;
  top: 30px;
  width: 155px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 10;
}

.colors-container.show {
  display: flex;
}
