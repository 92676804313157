/* Styles for form */
.form-error-message {
  color: red;
  font-size: 14px;
  margin-left: 13px;
  margin-top: 3px;
}

.required-asterisk {
  color: red; /* Change the color to red */
  margin-left: 0.2em;
}

.ws-form-label {
  font-size: 14px;
  margin-bottom: 0 !important;
  font-weight: bold;
}

.question-instructions-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.far.fa-lightbulb {
  color: #fff;
  background-color: #ffa500;
  padding: 9px;
  border-radius: 90%;
  padding-left: 10px;
  padding-top: 12px;
}

.toast-right-to-left .Toastify__progress-bar {
  transform-origin: right;
}

.toast-width-large {
  width: 425px;
}

.loader-inside-modal-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.form-check-input {
  cursor: pointer;
  border-color: black !important;
  margin-top: 2px !important;
}

.font-size-16px {
  font-size: 16px;
}

.mw-100px {
  max-width: 100px;
}

.mw-200px {
  max-width: 200px;
}

.btn-outline {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
}

.btn-outline:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-outline:focus {
  outline: 2px solid #000 !important;
}

.btn-close {
  margin: 0 !important;
  padding: 0.25rem !important;
}
