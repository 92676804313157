.welcome-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);

  .card-container {
    width: 470px;
    box-shadow: 0px 0px 8px #c0c0c0;

    .card-body {
      padding: 2.5em;
    }
  }

  .big-title {
    font-family: 'Helvetica Neue Light', sans-serif;
    font-size: 34px !important;
    line-height: 1em;
    text-align: center;
    height: 55px;
  }

  .small-text {
    font-family: 'Helvetica Neue Light', sans-serif;
    font-size: 18px;
    text-align: center;
    margin: 0;
  }

  .horizontal-rule {
    border: 1px solid #e8e8e8 !important;
    margin: 0;
  }

  .center-text {
    font-family: 'Helvetica Neue Light', sans-serif;
    font-size: 18px;
    line-height: 1em;
    height: 33px;
    text-align: center;
    margin: 0;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  .three-step-setup-container {
    display: flex;
    flex-wrap: nowrap;

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;

      .number {
        font-family: 'Helvetica Neue Light', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        width: 70px;
        font-size: 34px;
        color: #4d4d4d;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #4d4d4d;
      }

      .step-setup-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2em;
      }
    }
  }

  .start-button {
    width: 100%;
    height: 40px;
    font-weight: normal;
    border: none;
    margin-top: 20px;
  }
}
