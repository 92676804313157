.custom-modal {
  /* Styles for modal */
  font-family: 'Helvetica', sans-serif;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;

  .modal-dialog.modal-md {
    max-width: min(600px, 90%); /* Increase width up to 90% of the viewport width */
  }

  .modal-dialog.modal-sm {
    max-width: min(500px, 100%); /* Increase width up to 90% of the viewport width */
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-content {
    max-height: 90vh;
  }

  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 12px 11px 12px;
    i[title='Close'] {
      cursor: pointer;
    }
  }

  .custom-modal-header .modal-title {
    font-size: 20px;
  }

  .custom-modal-footer {
    padding: 4px 12px 3px 10px !important;
    gap: 0.25rem;
  }
}
