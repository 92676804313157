@import url('bootstrap/dist/css/bootstrap.css');
@import url('bootstrap-icons/font/bootstrap-icons.css');

/*
$white: #fff !default;
$bg-gray: #f8f9fa !default;
$border-gray: #E8E8E8 !default;

$primary-color: #ff5722;
$font-family: 'Arial', sans-serif;

$primary: #009efb;
$info: #604dcf;
$danger: #f62d51;
$success: #39c449;
$warning: #ffbc34;
$dark: #343a40;
$light: #eaf2fb;
$secondary: #35363b;
*/

/* Firefox custom scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #666e6b #f5f5f5;
}

/* WebKit (Chrome, Safari) custom scrollbar styles */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c0c0c0;
}

*::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

html,
body,
#root {
  font-family: Helvetica, sans-serif;
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #2480b3;
  --toastify-color-success: #00a413;
  --toastify-color-warning: #ee802b;
  --toastify-color-error: #d90012;
  --bs-link-color: #006a8d;
  height: 100%;
  width: 100%;
}

::placeholder {
  font-family: Arial, Helvetica, sans-serif;
}

::-ms-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.body-container {
  padding: 0 1rem;
  margin: 0 auto;
  background-color: #f8f9fa;
  content: 'width=device-width, initial-scale=1';
}

.body-container .panel {
  padding: 0;
  background-color: #fff;
}

.body-container .panel-left {
  margin: 0;
  border-right: 10px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
}

.body-container .panel-right {
  margin: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
}

.tab-container {
  background-color: #f8f9fa;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #009efb;
  color: white;
  padding: 1rem;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.content {
  padding: 1rem;
  font-family: 'Arial', sans-serif;
}

.custom-drag-preview {
  font-size: 14px;
  padding: 10px;
  background-color: #f8f8f8;
  width: 50% !important;
}

/* Spacing Utilities */
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pearson-heading {
  font-size: 20px;
  margin-bottom: 0px;
}

.pointer {
  cursor: pointer;
}

.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #007fa9 !important;
  --bs-btn-border-color: #006a8d !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #007fa9 !important;
  --bs-btn-hover-border-color: #006a8d !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #007fa9 !important;
  --bs-btn-active-border-color: #006a8d !important;
  --bs-btn-disabled-color: #eef8fc !important;
  --bs-btn-disabled-bg: #a0d1e1 !important;
  --bs-btn-disabled-border-color: #a0d1e1 !important;
  --bs-btn-focus-shadow-rgb: #000 !important;
}

.btn-primary:focus {
  outline: 2px solid #000 !important;
}

.btn-secondary {
  --bs-btn-color: #4d4d4d !important;
  --bs-btn-bg: #f0f0f0 !important;
  --bs-btn-border-color: #d0d0d0 !important;
  --bs-btn-hover-color: #4d4d4d !important;
  --bs-btn-hover-bg: #d0d0d0 !important;
  --bs-btn-hover-border-color: #d0d0d0 !important;
  --bs-btn-active-color: #4d4d4d !important;
  --bs-btn-active-bg: #f0f0f0 !important;
  --bs-btn-active-border-color: #d0d0d0 !important;
  --bs-btn-disabled-color: #cacaca !important;
  --bs-btn-disabled-bg: #f8f8f8 !important;
  --bs-btn-disabled-border-color: #e8e8e8 !important;
  --bs-btn-focus-shadow-rgb: #000 !important;
}

.btn-secondary:focus {
  outline: 2px solid #000 !important;
}

.text-box {
  background-color: #007fa9;
}

input[type='text']:focus {
  outline: 2px solid #000 !important;
}

input[type='search']:focus {
  outline: 2px solid #000 !important;
}

.nav-item a:focus {
  outline: 2px solid #000 !important;
}

.nav-item a:focus-visible {
  box-shadow: none;
  outline: none !important;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    border: 2px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.Toastify__close-button {
  opacity: 1 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  --toastify-color-info: #2480b3;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  --toastify-color-success: #00a413;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  --toastify-color-warning: #ee802b;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  --toastify-color-error: #d90012;
}

.close-tab:focus {
  outline: 2px solid #000 !important;
}

/* .resource-tab-links {
  padding-top: 7px;
} */

[data-bs-theme='light'] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
}

.disabled-link {
  pointer-events: none;
  color: #828991;
}

.flex-column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}
