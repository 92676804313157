.question-banks-tips-container {
  position: relative;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 0px;
  margin-top: 4.25rem;
  padding: 2.5rem;
}

.question-banks-tips-container::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px; /* Adjust the height of the bottom border */
  background-color: transparent; /* Initial transparent bottom border */
  transition: background-color 0.5s ease; /* Add a smooth transition effect */
}

.question-banks-tips-container:hover::after {
  background-color: rgb(247, 241, 241); /* Change the color on hover */
}

.container {
  text-align: left;
  margin-top: 10px;
  margin-left: 4px;
  max-width: 100%;
  margin-left: 20px !important;
  padding: 12px;
}

.bold-text {
  margin: 0;
  padding: 0;
}

.question-banks-tips-container .flex-box {
  display: flex;
  align-items: start;
  gap: 1.25em;
}
