.App-header .bg-body-tertiary {
  background-color: #000 !important;
}

.App-header .navbar-brand,
.App-header .nav-link,
.App-header .nav-link.show {
  color: #fff !important;
}

.divider {
  background-color: #e8e8e8;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  position: absolute;
  z-index: 9;
  height: 100%;
  width: 10px;
}
.container-resource {
  overflow: hidden;
}

.container-test {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  gap: 3px; /* Adjusted gap between buttons */
  align-items: center;
  justify-content: flex-start; /* Changed to align buttons closer */
  padding: 3px;
  background-color: #ddd; /* Light grey background color */
}

.resource-tab,
.create-or-edit-test,
.your-questions-folders-container,
.test-list {
  font-size: 24px;
}
