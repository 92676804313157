.print-preview-modal > .modal-dialog > .modal-content {
  min-height: 515px;
  height: 80vh;

  & > .modal-body {
    overflow: hidden !important;
  }

  & > .modal-body {
    padding: 0;
  }
}

.print-preview-left {
  width: 40%;

  .print-options {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .print-options-header {
    padding: 1rem 2rem;

    h3 {
      margin: 0;
    }
  }

  .print-options-body {
    .row {
      margin: 0;
    }
  }

  .print-options-info-text {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
    font-style: oblique;
    font-weight: bold;
    font-size: medium;
    text-align: center;
  }

  .question-no {
    position: relative;
    left: 0px;
  }

  .fa-solid.fa-circle-info {
    color: blue;
  }

  i.bi.bi-info-circle-fill.me-1 {
    color: #145ae1;
    font-size: 19px;
  }

  .student-name {
    padding-block: 0.75rem;
  }

  .add-remove-buttons {
    /* position: absolute; */
    left: 20px;
  }
}

.print-preview-right {
  width: 60%;

  .print-preview {
    border-left: 5px solid #ccc;
    display: flex;
    flex-direction: column;
    height: 100%;

    .print-preview-header {
      padding: 1rem 1rem 1rem 2rem;

      h3 {
        margin: 0;
      }
    }

    .questions-list {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.student-name {
  padding-bottom: 1rem;
}

/**
  * Print preview css
  */

@media print {
  @page {
    margin: 2cm;
  }

  .print-content {
    margin: 0; /* Reset margins to use padding for consistent spacing */
    padding: 1cm;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
    font-family: Arial, sans-serif;
  }
}
