.AlignmentDropdown {
  position: relative;
  margin: 0 1px;
}

.AlignmentDropdown .select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0.5rem;
  border: 1px solid #ccc;
  min-width: 50px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px;
  height: 25.6px;

  &:hover {
    transform: translateY(1px);
    color: #34495e;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #34495e;
  }
}

.AlignmentDropdown .arrow {
  border: solid #a4a2a2;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.AlignmentDropdown .arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 7px;
}

.AlignmentDropdown .options {
  display: none;
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid #ccc;
  font-size: 13px;
}

.AlignmentDropdown .options.show {
  display: block;
}

.AlignmentDropdown .option {
  padding: 3px 0.5rem;
  border-bottom: 1px solid #f1f1f1;
  color: #595959;

  &:last-child {
    border: none;
  }

  &:hover {
    color: #000;
    background-color: #ccc;
    cursor: pointer;
  }

  &.selected {
    background-color: #ccc;
    color: #000;
  }
}

.AlignmentDropdown .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -3px;
}
