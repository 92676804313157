.profile-dropdown .dropdown-menu.show {
  min-width: 220px;
  max-width: 300px;
  line-height: 1em;
  border-radius: 5px;
  padding-bottom: 0px;
}

.user-details {
  gap: 0.5rem;
  width: 100%;
}

.profile-dropdown .profile-name {
  text-align: center;
  font-weight: bold;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-dropdown .profile-email {
  text-align: center;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-dropdown .button-setting {
  margin-left: -91px;
  margin-bottom: -38px;
}

.profile-dropdown .button-signout {
  margin-left: 96px;
}

.profile-dropdown .navlink {
  text-decoration: none;
  margin-left: 6px;
}

.profile-dropdown .profile-name,
.profile-dropdown .profile-email {
  pointer-events: none;
  cursor: default;
}

.profile-dropdown .profile-name:hover,
.profile-dropdown .profile-email:hover {
  background-color: transparent;
}

.profile-dropdown .dropdown-menu.show::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0;
  border-color: transparent transparent #fff;
  margin-left: 162px;
  transition: transform 0.3s ease;
  transform-origin: center bottom;
}

.profile-dropdown .dropdown-menu.show.show::after {
  transform: rotate(0deg) translateY(-131px);
}

#nav-dropdown .dropdown-toggle:focus {
  outline: 1px solid white !important;
  border-radius: 2px;
}

#help-icon:focus {
  outline: 1px solid white !important;
  border-radius: 2px;
}

.brand {
  cursor: default;
  min-width: 0;
}

#profile-tooltip {
  margin-top: 7px;
}

#help-tooltip {
  margin-top: 7px;
}

.authentication-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding-top: 80px;
}

.login-card-container {
  width: 361px;
  height: 178px;
  box-shadow: 0px 0px 8px #c0c0c0;
  overflow: hidden;
}
