/* Modalpopuplist.css */

.folders-list {
  display: flex;
  flex-direction: column;
  max-height: 350px;

  .button-container button {
    margin-right: 10px;
  }

  .root-folders-tests {
    height: calc(-5px + 48vh);
    overflow-y: auto;
  }

  .search-box {
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 2px;
  }

  .search-box .form-control {
    width: 390px;
    margin-right: 3px;
  }
  .button-box {
    display: flex;
    align-items: center;
  }
  .button-box button {
    margin-left: 4px;
  }
}
