/* styles.css */

.test-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.test-container h3 {
  margin-top: -11px;
}

.test-container label {
  display: block;
  margin-bottom: -20px;
  margin-top: -3px;
}

.dropdown-option {
  padding: 5px 0; /* Adjust padding as needed */
}

.test-name-edit-container {
  padding-top: 0.6rem !important;
  padding: 0 0.75rem;
}

.test-questions-container {
  flex: auto 1 1;
  padding: 0.5rem;

  img {
    max-width: 100%;
  }
}

input.rounded.form-control {
  margin-left: 0px;
  margin-top: 0px;
}

input.rounded.form-control.testNameInputBox {
  max-width: 548px;
  width: 100%;
}

.editmode {
  overflow: auto;
  max-height: calc(60vh - 50px);
}

.paddingright10 {
  padding-right: 10px;
}

#example-collapse-text {
  margin-bottom: 26px;
}

.savelink {
  font-size: 17px;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 32px;
  background-color: transparent;
}

.deletelink {
  font-size: 14px;
  border: none;
  border-radius: 3px;
  width: 36px;
  height: 30px;
  background-color: transparent;
}

.editbtn {
  font-size: 14px;
  border: none;
  border-radius: 3px;
  width: 36px;
  height: 30px;
  background-color: transparent;
  padding-left: 5px;
}

.deletebtn {
  font-size: 14px;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  /* padding-right: 3em; */
}

/* .d-flex.justify-content-between {
  padding: 3px;
} */

.tabs-and-buttons-container {
  margin-left: -1px;
}

.drag-container {
  min-height: 100px;
  margin: 5px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius) !important;
}

.ml-2 {
  margin-left: -0.5rem !important;
  white-space: nowrap;
}

.metadata-container .mc--orientation label {
  margin-top: 0px;
}

.metadata-container {
  margin-left: 20px;
  margin-bottom: 5px;
}

.view-question {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.3rem;
  width: inherit;
  min-height: 28px;
}

.view-question .text-section {
  display: inline-flex;
}

.view-question .text-section .answer {
}

.view-question .icon-ml {
  margin-left: 25px;
}

.view-question .bi-check::before {
  font-size: x-large;
}

/* .view-question .checked {
  margin-left: -0.5rem;
} */

.fib-content-area {
  min-height: 80px;
}

.opt-close-button.highlight-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: black;
}

.opt-close-button.highlight-icon-button i {
  font-size: 0.9em;
  transition: color 0.3s;
  font-weight: bold;
}

.metadata-fields-container {
  .d-flex {
    label {
      width: 170px;
      margin-bottom: 0;
    }
  }
}

.blanksize {
  font-family: Arial, Helvetica, sans-serif;
}
