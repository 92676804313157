.search-box {
  .input-group-append {
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 0;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    z-index: 10;
  }

  .pointer {
    cursor: pointer;
  }

  input.form-control.rounded {
    padding-right: 35px;
  }
}

.form-control::placeholder {
  position: relative !important;
  top: 1px;
}
