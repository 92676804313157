.custom-questions-tab {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  .question-template-layout {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    cursor: move;

    .question-template-item {
      position: relative;
      background-color: #f8f8f8;
      font-size: 14px;
      color: #4d4d4d;
      position: relative;
      margin-top: 2px;

      .question-content {
        background-color: #e0e0e0 !important;
        border-bottom: 1px solid #d0d0d0 !important;
        padding: 0.6rem 1em;
      }

      .question-answers {
        .icon-section {
          min-width: 0;
        }
      }

      .question-templates-plus-icon {
        position: absolute;
        right: 10px;
        top: 8px;
        width: 25px;
        height: 25px;
        padding: 0;
        border: none;
        z-index: 1;
        background: transparent;
        border-radius: 2px;

        i {
          position: relative;
          top: 2px;
        }

        &:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
