.create-user-modal {
  .createuser-password {
    padding-right: 27px !important;
  }

  .user-role {
    margin-left: 3px;
    position: relative;
  }

  .select-role {
    margin-right: 21px;
    margin-top: 6px;
    font-weight: bold;
  }
}

.manage-users-tab-container {
  position: relative;
  padding: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .users-list-actions-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    z-index: 1;

    & > * {
      flex: 1;
    }

    .users-list-actions-left {
      min-width: 300px;
    }

    .users-list-actions-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
      min-width: 310px;
    }
  }

  .user-role-details {
    text-align: center;
    display: grid;
    justify-content: center;
  }

  .user-card-text {
    font-size: 15px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-icon {
    color: #757474;
    padding: 27px;
    border-radius: 50%;
    background: #d8dbdb99;
  }

  .custom-card-user {
    background: #f2f2f299 !important;
    box-sizing: border-box;
    transition:
      box-shadow 0.3s ease,
      transform 0.3s ease;
  }

  .custom-card-user:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transform: translateY(2px);
  }

  .ptb-resource {
    background: #f8f9fa;
  }

  .search-user {
    margin-left: 15px;
  }

  .user-name {
    margin-top: 6px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  .no-matching-users {
    position: absolute;
    text-align: center;
    height: 100%;
    top: 0;
    left: 0;
  }

  .user-card-body-top {
    display: grid;
    justify-content: center;
    background: white;
    border-radius: 6px;
  }

  .user-card-body-bottom {
    background: #d8dbdb99;
  }

  .users-list-row {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    row-gap: 1em;
    padding-bottom: 1rem;
    overflow-y: auto;
  }

  .user-card-buttons {
    padding: 4px 7px !important;
    border-radius: 4px !important;
  }

  .custom-card-user .user-card-body-top {
    padding-bottom: 6px;
  }

  .fa-user-edit:before,
  .fa-user-pen:before {
    position: relative;
    left: 7px;
  }

  .fa-user-pen,
  .fa-user-edit {
    padding: 27px 21px;
  }

  .button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    gap: 0.5rem;
  }

  .disabled-card {
    opacity: 0.3;
  }

  .disabled-card:hover {
    opacity: 1;
  }
}
