.your-questions-folders-container {
  overflow-x: hidden;
  height: 100%;
}

.closebtn {
  margin-left: 8px;
}

.saved-questions {
  border: 1px solid #d8d8d8;
}

.saved-questions > div {
  border: 1px solid #f4f4f4;
}

.no-folders {
  background-color: #f5f5f5;
  padding: 24px;
  margin-top: 84px;
  position: fixed;
  top: 192px;
  left: 7px;
  z-index: 1;
  width: 48%;
}

.no-folders h4 {
  margin-top: 0;
}

.no-folders p {
  margin: 3px 0;
  margin-left: 51px;
  word-spacing: 4px;
}

.question-title {
  margin-left: 20px;
}
