/* QuestionBanks.css */
.questionBank {
  margin-top: 6px;
}
.questionBanksearchcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.advanced-search-container {
  padding: 5px;

  color: white;
  border-radius: 10px;
}

.bodyas-container {
  content: 'width=device-width, initial-scale=1';
  width: 518px;
  max-width: 568px;
  margin: 0 auto;
}

@media (min-width: 1536px) and (min-height: 846px) {
  .bodyas-container {
    width: 568px !important;
  }
}

.advanced-search-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type-and-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.advanced-button-options {
  gap: 10px;
  margin: 7px 7px 2px 7px;
}

.type-label {
  font-weight: bold;
  color: black;
  padding-left: 7px;
}
.buttons-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}
.advanced-button {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.advanced-action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 7px;
}

.advanced-action-buttons > button {
  width: auto;
}

.discipline-books {
  flex-grow: 1;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 0.1px;
  right: -0.1px;
  font-size: 1.5rem;
  color: #000 !important;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}
.custom-divider {
  height: 1px;
  background-color: #ccc;
}

.questionBanksearchcontainer {
  .button-link {
    font-size: 0.9em;
    text-decoration: none;
    padding: 6px;
    color: var(--bs-link-hover-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

.meta-data-filter {
  display: flex;
  gap: 13px;
  position: relative;
  right: 33px;
  width: 504px;
}
