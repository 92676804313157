.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* adjust the z-index as needed to ensure it overlays other content */
}

.spinner:focus {
  outline: 2px solid #ddd7d7 !important;
}
