.password-input-field {
  position: relative;

  .pwd-toggle-icon {
    position: absolute;
    right: 0px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 26px;
    cursor: pointer;
  }
}
