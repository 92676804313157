/* input.rounded.form-control {
    margin-left: -14px;
    margin-top: -5px;
  } */

button.close-tab.btn.btn-link {
  color: black;
  padding: 0px 9px 0px 9px;
  height: 1.5rem;
}

#dropdown-ellipsis.dropdown-toggle::after {
  display: none;
}

#dropdown-ellipsis + .dropdown-menu .dropdown-item {
  padding-right: 7px;
}

#btn-test-wizard {
  visibility: hidden;
}

.custom-tabs {
  display: flex;
  overflow-x: auto;
}

#tab-label-dropdown #close-tab-dropdown {
}

#test-tabs-navlink {
  padding-right: 0px;
  padding-left: 10px;
  width: 125px;
}

.tab-label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-title {
  text-align: center;
  white-space: nowrap;
  max-width: 80px;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .close-tab-wrapper {
    position: absolute;
    top: 0;
    right: -10px;
  } */

span.tab-separator {
  margin-right: 5px;
}

a.active.custom-add-new-test.nav-link {
  background-color: rgb(159, 155, 155);
  color: white;
  padding: 8px 13px;
}

.tab-label .test-title {
  text-align: left;
}
