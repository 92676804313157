/* css for add discipline */
.left-content {
  font-size: 16px;
}

.disciplineaddpopup-container {
  background-color: #ffff;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  max-height: 430px;
  min-height: 430px;
}

.disciplineaddpopup-top-container {
  display: flex;
  align-items: center;

  background-color: #f8f8f8;
  color: #4d4d4d;
  line-height: 1em;
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px;
  justify-content: space-between;
}

.disciplinePopup.search-container {
  width: 55%;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.disciplinePopup.btn.btn-primary {
  font-size: 14px;
  align-self: self-end;
}

.disciplinePopup.input-group {
  position: relative;
  width: 100%;
}

.disciplinePopup.result-list {
  width: 100%;
  list-style: none;
  padding: 0;
  position: relative;
  height: calc(60vh - 85px) !important;
  overflow: auto;
}

.disciplinePopup.result-list li {
  padding-block-start: inherit;
  cursor: pointer;
  border-top: 1px solid #d0d0d0;
  font-size: 14px;
  padding: 5px;
}

.disciplinePopup.result-list li:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.disciplinePopup.result-list li.selected {
  background-color: #007fa9;
  box-shadow:
    3px - Inset top,
    #006a8d;
  color: #ffffff;
}

.choose-your-discipline {
  font-size: 24px;
  margin-bottom: 0px;
}

.no-matching-discipline-message {
  align-self: center;
  padding-inline-start: 10px;
  padding-top: 10px;
}
.no-data-message {
  align-self: center;
  padding-inline-start: 10px;
  padding-top: 30px;
}

input.disciplinePopup.form-control.rounded.search-inputText {
  margin-left: 2px;
}
