.print-test-tree-view {
  .print-view-container {
    &.line-height-2 {
      line-height: 1.6rem !important;
    }

    img {
      max-width: 100%;
    }
  }

  /* adds space between questions */
  .question-container {
    margin-bottom: 1.5rem;
  }

  .question-answer-option {
    .icon-section {
      min-width: 5px !important;
    }
  }

  .left-side-margin {
    margin-left: 60px;
  }
}
