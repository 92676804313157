.draggable-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2px;
  padding-inline: 5px;
  cursor: grab;
  opacity: 0.5;
  border-right: 1px solid #e0e0e0;
  transition: 0.2s all ease-in;
  background: #f8f8f8;

  &:hover {
    background: #ebebeb;
  }
}

.grabbing .draggable-icon {
  cursor: grabbing;
}

.clicked .draggable-icon {
  background-color: #007fa9;
  color: white;
}
