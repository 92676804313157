.ws-login-screen {
  .login-card {
    width: 23rem;
    border-radius: 3px !important;
  }

  .card-title {
    position: relative !important;
    bottom: 12px !important;
  }

  .card-body {
    padding: 21px 27px 27px 24px !important;
  }

  .forgot-password {
    float: right;
  }

  .login-button {
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    height: 39px;
  }

  .signup-link {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #9d9696;
  }

  .pearson-logo {
    text-align: center;
    position: relative;
    top: 0px;
    background: #f3f3f3;
    padding: 10px;
    border-bottom: 1px solid #e9e0e0;
    border-radius: 3px;
  }

  .login-title {
    text-align: center;
    color: #807d7d !important;
  }

  .password {
    margin-top: 12px;
  }

  .show-password {
    margin-top: 10px;
    font-size: 14px;
    color: #9d9696;
  }

  .textbox-password {
    padding-right: 40px; /* To avoid overlap with the icon */
  }

  .formlabel-username,
  .formlabel-password {
    position: relative;
    top: 8px;
  }

  .error-message {
    position: relative;
    left: 5px;
    color: rgb(209, 58, 58);
  }

  .instructor-signin {
    width: 23rem;
    margin: auto;
    margin-top: 5px;
    text-align: center;
    padding: 10px;
  }

  .sign-in-instructor {
    color: #007fa9;
  }
}
