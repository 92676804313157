.question-container {
  .question-content {
    word-break: break-word;
    align-self: center;
  }

  ul {
    list-style: disc !important;
  }

  ol {
    list-style: decimal !important; /* Added important because for migrated data bullet icons were overriding with other css style */
  }

  ul,
  ol {
    margin-left: 1em;
    cursor: default;
    padding: 0;
  }
}

.question-answers {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.question-answer-option {
  display: flex;
  align-items: flex-start;

  .icon-section {
    font-size: 1.2rem;
    min-width: 1.5rem;
    max-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .answer-option-text {
    display: flex;
    gap: 0.25rem;
  }
}

.add-blank-container {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-block: 1rem;
}

.horizontal-options {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.blank-line {
  font-weight: inherit !important;
  font-style: inherit !important;
  text-decoration: none !important;
  color: #000 !important;
  font-size: inherit !important;
  font-family: inherit !important;
  padding-inline: 5px;
}
