.settings-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.settings-block {
  margin-bottom: 1rem;
}

.action-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.export-modal-popup {
  hr {
    margin: 0.25rem 0 !important;
  }
  form .row {
    padding-bottom: 8px;
  }
}
