.treeview {
  height: 100%;
  cursor: grab;
}

.treeview.grabbing {
  cursor: grabbing;
}

.treeview > ul {
  box-sizing: border-box;
  height: 100%;
  padding: 5px 0px;
  list-style: none;
}

.test-folder-tree-node {
  display: flex;
  align-items: start;
  padding: 10px;
  padding-right: 65px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #4d4d4d;
  position: relative;
  line-height: 1em;
  margin-top: 2px;

  .caret-container {
    cursor: pointer;
  }

  .tree-node-text {
    padding-left: 8px;
  }

  .tree-node-action-buttons-container {
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    background-color: transparent;
    width: 68px;

    .action-button {
      border-radius: 3px;
      background-color: #f8f8f8;
      border: none;
      padding: 8px;

      &:hover {
        border-color: #007fa9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

      &.selected {
        border-color: #007fa9;
        background-color: #007fa9;
      }

      &.highlight {
        border-color: #007fa9;
        background-color: #007fa9;
      }
    }
  }

  /* &.clicked .tree-node-action-buttons-container {
    .action-button {
      background: transparent;
      color: #fff;

      &:hover {
        box-shadow: 0 2px 4px rgba(255, 255, 255, 0.8);
      }

      &.selected {
        background-color: #f8f8f8;
        color: #000;
      }
    }
  } */
}

/* .tree-node.clicked {
  background-color: #007FA9;
  color: white;
} */

#delete-modal-header {
  padding: 10px;
}

#delete-modal-footer {
  padding: 0.5rem;
  border-bottom: 1px solid #e9ecef;
  padding: 10px;
}

.testbtn {
  border: none;
  text-align: justify;
  padding: 5px;
  width: 100%;
  margin-bottom: -5px;
  background-color: #f8f8f8;
  color: #4d4d4d;
  font-size: 14px;
  position: relative;
}

.testbtn i {
  margin-left: 5px;
}

.book-dropdown {
  margin-top: 6px;
  margin-bottom: -4px;
  color: #4d4d4d;
  text-align: justify;
  font-size: 14px;
  position: relative;
  margin-left: 13px;
}

.test-dropdown {
  margin-top: 7px;
  margin-bottom: -5px;
  background-color: #f8f8f8;
  color: #4d4d4d;
  text-align: justify;
  font-size: 14px;
  position: relative;
  margin-left: 22px;
}

.editbutton.highlight {
  background-color: #007fa9; /* Set your desired highlight color */
}

.test-item {
  padding: 5px;
}

.info {
  background-color: transparent;
  border: none;
  position: absolute;
  border-radius: 3px;
  right: 0px;
  padding: 1px 9px 2px 8px;
  margin: -1px 1px 1px 1px;
}

.info:hover {
  border-color: #007fa9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.info.selected {
  background-color: #007fa9;
  color: #fff;
}

.maigratedtests {
  margin-top: 5px;
  cursor: pointer;
}

.your-test-list .treeview {
  height: auto !important;
}
