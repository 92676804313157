.treeview {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.treeview ul {
  list-style: none;
}

.treeview > ul {
  box-sizing: border-box;
  height: 100%;
  padding: 5px 0px;
}

.tree-node {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 10px;
  font-size: 14px;
  line-height: 1em;
  margin-top: 2px;
}

.tree-node-header {
  margin-right: 5px;
}

.nested-nodes {
  margin-left: 15px;
}

.booktab-container {
  background-color: #ffff;
  color: #4d4d4d;
  justify-content: space-around;
  max-height: 430px;
  min-height: 430px;
}

.top-containerbooks {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  color: #4d4d4d;
  line-height: 1em;
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px;
}

.top-container h4 {
  margin: 0;
  text-align: center;
  line-height: 1.5;
}

.booktab.btn.btn-secondary {
  margin-inline-start: auto;
  margin-right: 10px;
  font-size: 14px;
}

.booktab.btn.btn-primary {
  font-size: 14px;
}

.booktab.search-container {
  width: 55%;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.booktab.input-group {
  position: relative;
  width: 100%;
}

.booktab.search-input {
  min-width: 200px;
  width: 100%;
  transition: none;
}

.booktabaddpopup.result-list {
  width: 100%;
  list-style: none;
  padding: 0;
  /* height: calc(60vh - 85px)!important; */
  overflow: auto;
  /* border-top: 1px solid #d0d0d0; */
  max-height: 299px;
}

.booktabaddpopup.result-list li {
  padding-block-start: inherit;
  cursor: pointer;
  border-top: 1px solid #d0d0d0;
}

.booktabaddpopup.result-list li:last-child {
  border-bottom: none;
}

.tree-node.selected {
  background-color: #007fa9;
  box-shadow: 3px 3px 5px inset #006a8d;
  color: #ffffff;
}

#search-addon {
  border: 1px solid #212529 !important;
  margin-top: 4px;
}

#search-addon-noborder {
  margin-top: 10px;
}

.choose-your-books-or-topics {
  font-size: 24px;
  margin-bottom: 0px;
}

.no-matching-books-message {
  align-self: center;
  padding-inline-start: 10px;
  padding-top: 20px;
}

input.form-control.rounded.search-inputbox {
  margin-left: 1px;
}
