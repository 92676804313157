.manage-workspace-container {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .workspace-list-actions-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    z-index: 1;

    & > * {
      flex: 1;
    }

    .workspace-list-actions-left {
      min-width: 300px;
    }

    .workspace-list-actions-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
      min-width: 310px;
    }
  }

  .table .btn-sm {
    padding: 0px;
    width: 50px;
  }

  .no-matching-workspaces {
    position: absolute;
    text-align: center;
    height: 100%;
    top: 0;
    left: 0;
  }

  table {
    display: block;
    max-height: calc(100vh - 177px);
    overflow-y: auto;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  table thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 2;
    text-align: left;
  }
}

.table td.workspace-name-td,
.table td.workspace-description-td {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
