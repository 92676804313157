.left-content {
  font-size: 16px;
}
.left-span {
  padding-left: 10px;
}
.discipline-container {
  background-color: #ffff;
  color: #4d4d4d;
  justify-content: space-around;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  color: #4d4d4d;
  line-height: 1em;
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px;
}
.top-container h4 {
  margin: 0;
  text-align: center;
  line-height: 1.5;
  font-size: 20px;
}

.discipline.search-container {
  width: 55%;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: flex-end;
}
.discipline.btn.btn-primary {
  font-size: 14px;
}

.discipline.input-group {
  position: relative;
  width: 100%;
}

.discipline.search-input {
  min-width: 200px;
  width: 100%;
  transition: none;
}

.discipline.result-list {
  width: 99%;
  list-style: none;
  padding: 0;
  position: relative;
  height: calc(77vh - 85px);
  overflow: auto;
}

.discipline.result-list li {
  padding-block-start: inherit;
  cursor: pointer;
  border-top: 1px solid #d0d0d0;
  font-size: 14px;
  padding: 5px;
}

.discipline.result-list li:last-child {
  border-bottom: 1px solid #d0d0d0;
}

.discipline.result-list li.selected {
  background-color: #007fa9;
  box-shadow:
    3px - Inset top,
    #006a8d;
  color: #ffffff;
}

.choose-your-discipline {
  font-size: 24px;
  margin-bottom: 0px;
}
.no-matching-discipline-message {
  align-self: center;
  padding-inline-start: 10px;
  padding-top: 30px;
}
